/******************************
	#page title
******************************/
.page-title {
  // @include background-style("../../images/page-title.jpg", center center, cover, no-repeat, local);
  width: 100%;
  height: 420px;
  text-align: center;
  position: relative;
  z-index: 1;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
  img::after {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }

  @include media-query(991px) {
    height: 300px;
  }

  &:before {
    content: "";
    @include overlay(transparentize($black, 0.6));
    z-index: -1;
  }

  .container {
    height: 100%;
    display: table;

    > .row {
      vertical-align: middle;
      display: table-cell;
    }
  }

  h2 {
    font-size: 50px;
    font-size: calc-rem-value(50);
    color: $white;
    margin: 0 0 0.3em;
    text-transform: capitalize;

    @include media-query(991px) {
      font-size: 45px;
      font-size: calc-rem-value(45);
    }

    @include media-query(767px) {
      font-size: 40px;
      font-size: calc-rem-value(40);
    }
  }

  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
    justify-content: center;

    @include media-query(767px) {
      text-align: center;
    }
  }

  .breadcrumb li {
    font-size: 16px;
    font-size: calc-rem-value(16);
    color: $white;
    margin-right: 5px;
    position: relative;

    @include media-query(767px) {
      font-size: 14px;
      font-size: calc-rem-value(14);
    }
  }

  .breadcrumb li a {
    // color: $theme-primary-color;
    color: $yellow;
  }

  .breadcrumb li a:hover {
    color: darken($theme-primary-color, 5%);
  }

  .breadcrumb > li + li {
    margin-left: 20px;
    padding-left: 15px;
  }

  .breadcrumb > li + li:before {
    font-family: Flaticon;
    content: "\f103";
    padding: 0;
    position: absolute;
    left: -14px;
    top: 1px;
    color: $theme-primary-color;
  }
}
