/*--------------------------------------------------------------
#0.3	header
--------------------------------------------------------------*/
.site-header {
  .navbar-brand {
    margin-top: 7px;

    img {
      max-width: 175px;
      max-height: 37px;
    }

    @include media-query(991px) {
      margin-top: 0;
      padding-top: 0;
      margin-top: -30px;
      display: block;
    }
  }

  .navigation {
    background-color: $white;
    margin-bottom: 0;
    border: 0;
    @include rounded-border(0);
    padding: 0;

    > .container {
      position: relative;
    }
  }

  #navbar {
    font-family: $heading-font;
    @include transition-time(0.5s);
    padding-right: 90px;

    ul {
      list-style: none;
    }

    > ul li a:hover,
    > ul li a:focus,
    > ul > li .sub-menu a:hover {
      text-decoration: none;
      color: $theme-primary-color;
      text-shadow: 0.5px 0.5px grey;
    }

    > ul > .menu-item-has-children > a {
      position: relative;
    }

    > ul > .menu-item-has-children > a:before {
      font-family: "themify";
      content: "\e61a";
      font-size: 8px;
      font-size: calc-rem-value(8);
      position: absolute;
      right: 2px;
      top: 46%;
    }

    @include widther(1200px) {
      > ul > .menu-item-has-children > a:before {
        right: 6px;
      }
    }

    // style for widther screen
    @include widther(992px) {
      li {
        position: relative;
      }

      > ul > li > a {
        font-size: 16px;
        font-size: calc-rem-value(16);
        color: $heading-color;
        padding: 37px 20px;
        display: block;
      }

      > ul .sub-menu {
        background-color: $white;
        width: 220px;
        text-align: left;
        padding: 10px 25px;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        @include transition-time(0.3s);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
        text-transform: capitalize;
      }

      > ul > li .sub-menu li {
        border-bottom: 1px solid lighten($black, 95%);
      }

      > ul > li .sub-menu li:last-child {
        border-bottom: 0;
      }

      > ul > li .sub-menu a {
        display: block;
        padding: 10px 0;
        color: $heading-color;
        font-size: 14px;
        font-size: calc-rem-value(14);
      }

      > ul > li > .sub-menu .sub-menu {
        left: 112%;
        top: 0;
      }

      > ul > li > .sub-menu > .menu-item-has-children > a {
        position: relative;

        &:before {
          font-family: "themify";
          content: "\e649";
          font-size: 11px;
          font-size: calc-rem-value(11);
          position: absolute;
          right: 15px;
          top: 50%;
          @include translatingY();
        }
      }

      /*** hover effect ***/
      > ul > li:hover > .sub-menu {
        top: 100%;
        visibility: visible;
        opacity: 1;
      }

      .sub-menu > li:hover > .sub-menu {
        left: 114%;
        visibility: visible;
        opacity: 1;
      }
    }

    @include media-query(991px) {
      > ul > li a {
        display: block;
        font-size: 14px;
        font-size: calc-rem-value(14);
      }

      > ul > li .sub-menu li {
        border-bottom: 1px solid lighten($black, 90%);
      }

      > ul .sub-menu > li:last-child {
        border-bottom: 0;
      }

      > ul > li > .sub-menu a {
        padding: 8px 15px 8px 45px;
      }

      > ul > li > .sub-menu .sub-menu a {
        padding: 8px 15px 8px 65px;
      }

      > ul .menu-item-has-children > a {
        position: relative;

        &:before {
          font-family: "themify";
          content: "\e61a";
          font-size: 11px;
          font-size: calc-rem-value(11);
          position: absolute;
          right: 15px;
          top: 50%;
          @include translatingY();
        }
      }
    }

    /*** mega-menu style ***/
    @include widther(992px) {
      .has-mega-menu {
        position: static;
      }

      .mega-menu,
      .half-mega-menu {
        background-color: $white;
        padding: 20px;
        border-top: 2px solid $theme-primary-color;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        @include transition-time(0.3s);
      }

      .mega-menu {
        width: 1140px;
        right: 15px;
      }

      .half-mega-menu {
        width: 585px;
      }

      .mega-menu-box-title {
        font-size: 14px;
        font-size: calc-rem-value(14);
        text-transform: uppercase;
        font-weight: bold;
        display: block;
        padding-bottom: 7px;
        margin-bottom: 7px;
        border-bottom: 1px solid lighten($black, 90%);
      }

      .mega-menu-list-holder li a {
        font-size: 14px;
        font-size: calc-rem-value(14);
        display: block;
        padding: 7px 8px;
        margin-left: -8px;
      }

      /*** hover effect ***/
      .has-mega-menu:hover > ul {
        top: 100%;
        visibility: visible;
        opacity: 1;
      }
    }

    @include media-query(1199px) {
      > ul .mega-menu {
        width: 950px;
        right: 15px;
      }

      > ul .half-mega-menu {
        width: 485px;
      }
    }

    @include media-query(991px) {
      > ul .mega-menu,
      > ul .half-mega-menu {
        width: auto;
      }

      > ul .mega-menu .row,
      > ul .half-mega-menu .row {
        margin: 0;
      }

      .mega-menu-content > .row > .col {
        margin-bottom: 25px;
      }
    }

    @include media-query(991px) {
      .mega-menu .mega-menu-list-holder a {
        padding: 5px 15px 5px 40px;
      }

      .mega-menu .mega-menu-box-title {
        font-size: 14px;
        font-size: calc-rem-value(14);
        text-transform: uppercase;
        display: block;
        border-bottom: 1px dotted lighten($black, 70%);
        padding: 0 0 4px 5px;
        margin: 0 25px 8px 25px;
      }
    }
  }

  /* navigation open and close btn hide for width screen */
  @include widther(992px) {
    .navbar-header .open-btn {
      display: none;
    }

    #navbar .close-navbar {
      display: none;
    }
  }

  /* style for navigation less than 992px */
  @include media-query(991px) {
    .container {
      width: 100%;
    }

    .navbar-header button {
      background-color: $theme-primary-color;
      width: 40px;
      height: 35px;
      border: 0;
      padding: 5px 10px;
      outline: 0;
      position: absolute;
      right: 15px;
      top: calc(50% - 17.5px);
      z-index: 20;

      span {
        background-color: $white;
        display: block;
        height: 2px;
        margin-bottom: 5px;

        &:last-child {
          margin: 0;
        }
      }
    }

    #navbar {
      background: $white;
      display: block !important;
      width: 280px;
      height: 100% !important;
      margin: 0;
      padding: 0;
      border-left: 1px solid lighten($black, 80%);
      border-right: 1px solid lighten($black, 80%);
      position: fixed;
      right: -330px;
      top: 0;
      z-index: 100;

      ul a {
        color: $black;
      }

      ul a:hover,
      ul li.current a {
        color: $theme-primary-color;
      }

      .navbar-nav {
        height: 100%;
        overflow: auto;
      }

      .close-navbar {
        background-color: $white;
        width: 40px;
        height: 40px;
        color: $black;
        border: 0;
        outline: none;
        position: absolute;
        left: -41px;
        top: 90px;
        z-index: 20;

        .ti-close {
          position: relative;
          top: 1px;
        }
      }

      > ul > li {
        border-bottom: 1px solid lighten($black, 95%);
      }

      > ul > li > a {
        padding: 10px 15px 10px 35px;
      }
    }

    /* class for show hide navigation */
    .slideInn {
      right: 0 !important;
    }
  }

  @include media-query(767px) {
    .navbar-header .navbar-brand {
      font-size: 24px;
      // img {
      //   height: 50px;
      // }
    }

    #navbar .navbar-nav {
      margin: 0;
    }
  }

  /*navbar collaps less then 992px*/
  @include media-query(991px) {
    .navbar-collapse.collapse {
      display: none;
    }

    .navbar-collapse.collapse.in {
      display: block;
    }

    .navbar-header .collapse,
    .navbar-toggle {
      display: block;
    }

    .navbar-header {
      float: none;
    }

    .navbar-right {
      float: none;
    }

    .navbar-nav {
      float: none;
    }

    .navbar-nav > li {
      float: none;
    }
  }
}

@include media-query(991px) {
  .page-wrapper {
    @include transition-time(0.3s);
  }
  .body-overlay:before {
    content: "";
    width: 100%;
    height: 100%;
    background: transparentize($black, 0.1);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    @include transition-time(0.3s);
  }
}

/*---------------------------------------------
	header style 1
----------------------------------------------*/
.header-style-1 {
  @include widther(992px) {
    position: absolute;
    width: 100%;
    z-index: 100;

    &:before {
      background: transparentize($white, 0.9);
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 4px;
    }

    .navigation {
      background: transparent;
      background: rgba(255, 255, 255, 1);
    }

    #navbar {
      justify-content: center;

      > ul > li a {
        color: $theme-primary-color;
      }

      > ul {
        float: none;
      }
    }

    #navbar > ul > li > a {
      padding: 39px 15px;
    }
  }

  @include widther(1200px) {
    #navbar > ul > li > a {
      // padding: 39px 20px;
      padding: 25px 20px;
    }
  }

  @include media-query(991px) {
    .navigation {
      // background: #313131;
      background: transparent;
      padding: 17px 0;
    }
  }

  /*** cart-search-contact ***/
  // .cart-search-contact {
  //   position: absolute;
  //   right: 27px;
  //   top: 50%;
  //   @include translatingY();
  //   z-index: 10;

  //   @include media-query(991px) {
  //     right: 95px;
  //   }

  //   @include media-query(450px) {
  //     right: 80px;
  //   }

  //   button {
  //     background: transparent;
  //     padding: 0;
  //     border: 0;
  //     outline: 0;
  //   }

  //   .fi {
  //     font-size: 30px;
  //     font-size: calc-rem-value(30);
  //     color: $white;
  //   }

  //   > div {
  //     float: left;
  //     position: relative;
  //   }

  //   > div + div {
  //     margin-left: 20px;
  //   }

  //   .header-search-form {
  //     position: absolute;
  //     width: 250px;
  //     right: 0;
  //     top: 70px;
  //     opacity: 0;
  //     visibility: hidden;
  //     @include transition-time(0.5s);
  //     box-shadow: 0px 15px 60px -19px rgba(0, 0, 0, 1);

  //     @include media-query(991px) {
  //       top: 68px;
  //     }

  //     @include media-query(767px) {
  //       top: 63px;
  //       right: 15px;
  //     }
  //   }

  //   .header-search-form-wrapper .fi:before {
  //     font-size: 22px;
  //     font-size: calc-rem-value(22);
  //   }
  //   .header-search-form-wrapper .search-toggle-btn {
  //     padding-top: 6px;
  //   }

  //   form div {
  //     position: relative;

  //     button {
  //       position: absolute;
  //       right: 15px;
  //       top: 50%;
  //       @include translatingY();
  //     }
  //   }

  //   .mini-cart-close {
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     width: 30px;
  //     height: 30px;
  //     background: #ebebeb;

  //     i {
  //       color: $black;
  //       font-size: 15px;
  //     }
  //   }

  //   input {
  //     width: 100%;
  //     height: 50px;
  //     padding: 6px 20px;
  //     border: 0;
  //     border-radius: 0;
  //     box-shadow: none;
  //   }

  //   .mini-cart {
  //     @include media-query(450px) {
  //       display: none;
  //     }
  //   }

  //   .mini-cart .fi:before {
  //     font-size: 25px;
  //     font-size: calc-rem-value(25);
  //   }

  //   .mini-cart .cart-count {
  //     background: $theme-primary-color;
  //     width: 22px;
  //     height: 22px;
  //     line-height: 22px;
  //     font-size: 10px;
  //     font-size: calc-rem-value(10);
  //     color: white;
  //     position: absolute;
  //     top: 0;
  //     right: -11px;
  //     border-radius: 50%;
  //   }

  //   .mini-cart-content {
  //     background: $white;
  //     width: 300px;
  //     z-index: 10;
  //     position: absolute;
  //     right: 0;
  //     top: 70px;
  //     opacity: 0;
  //     visibility: hidden;
  //     @include transition-time(0.5s);
  //     box-shadow: 0px 15px 60px -19px rgba(0, 0, 0, 1);
  //     padding-top: 20px;

  //     @include media-query(991px) {
  //       top: 68px;
  //     }

  //     @include media-query(767px) {
  //       top: 63px;
  //     }

  //     @include media-query(450px) {
  //       right: auto;
  //       left: -185px;
  //     }

  //     p {
  //       font-family: $heading-font;
  //       font-size: 15px;
  //       font-size: calc-rem-value(15);
  //       font-weight: 500;
  //       color: $heading-color;
  //       margin: 0;
  //       text-transform: uppercase;
  //     }

  //     .mini-cart-items {
  //       padding: 25px 20px;
  //     }

  //     .mini-cart-item {
  //       padding-top: 15px;
  //       margin-top: 15px;
  //       border-top: 1px solid #efefef;
  //     }

  //     .mini-cart-item:first-child {
  //       padding-top: 0;
  //       margin-top: 0;
  //       border-top: 0;
  //     }

  //     .mini-cart-item-image {
  //       width: 50px;
  //       height: 50px;
  //       border: 2px solid #f3f3f3;
  //       float: left;
  //       margin-right: 15px;
  //     }

  //     .mini-cart-item-image a,
  //     .mini-cart-item-image img {
  //       display: block;
  //       width: 46px;
  //       height: 46px;
  //     }

  //     .mini-cart-item-des {
  //       position: relative;
  //       overflow: hidden;
  //     }

  //     .mini-cart-item-des a {
  //       font-size: 14px;
  //       font-size: calc-rem-value(14);
  //       font-weight: 500;
  //       text-align: left;
  //       color: $heading-color;
  //     }

  //     .mini-cart-item-des a:hover {
  //       color: $theme-primary-color;
  //     }

  //     .mini-cart-item-des .mini-cart-item-price {
  //       font-size: 13px;
  //       font-size: calc-rem-value(13);
  //       color: #888;
  //       display: block;
  //       margin-top: 3px;
  //     }

  //     .mini-cart-item-des .mini-cart-item-quantity {
  //       font-size: 12px;
  //       font-size: calc-rem-value(12);
  //       color: #444;
  //       display: block;
  //       position: absolute;
  //       right: 0;
  //       top: 2px;
  //     }

  //     .mini-cart-action {
  //       padding: 20px;
  //       border-top: 1px solid #efefef;
  //       text-align: center;

  //       .theme-btn-s2 {
  //         float: right;
  //       }
  //     }

  //     .mini-cart-action .mini-checkout-price {
  //       font-family: $heading-font;
  //       font-size: 18px;
  //       font-size: calc-rem-value(18);
  //       font-weight: 600;
  //       color: $heading-color;
  //       display: inline-block;
  //     }
  //   }

  //   .mini-cart-content-toggle,
  //   .header-search-content-toggle {
  //     opacity: 1;
  //     visibility: visible;
  //     right: 0;
  //   }

  //   button.btn {
  //     background: none;
  //     border: 0;
  //     outline: 0;
  //     padding: 0;
  //   }
  //   button.btn i {
  //     color: #323232;
  //     font-size: 12px;
  //   }
  // }
}

/*---------------------------------------------
	header style 2
----------------------------------------------*/
.header-style-2 {
  @extend .header-style-1;
  position: static;

  .navigation {
    @include widther(992px) {
      width: calc(100% - 60px);
      left: 30px;
    }

    @include widther(1200px) {
      width: calc(100% - 80px);
      left: 40px;
    }
  }

  @include widther(992px) {
    .navigation {
      position: absolute;
      z-index: 100;
    }
  }

  .topbar {
    padding: 18px 0;
    @include media-query(991px) {
      padding: 12px 15px;
    }

    @include media-query(767px) {
      text-align: center;
    }
  }

  .topbar .contact-info {
    display: inline-block;
    float: left;
    position: relative;
    top: -2px;

    @include media-query(767px) {
      display: block;
      float: none;
    }

    a {
      text-decoration: underline;
      color: $theme-primary-color;
    }

    a:hover {
      color: darken($theme-primary-color, 10%);
    }

    ul {
      overflow: hidden;
      list-style: none;

      li {
        float: left;

        @include media-query(767px) {
          float: none;
          margin-bottom: 8px;
        }
      }

      > li + li {
        margin-left: 50px;

        @include media-query(767px) {
          margin-left: 0;
        }
      }
    }
  }

  .topbar .social {
    display: inline-block;
    float: right;

    @include media-query(767px) {
      display: block;
      float: none;
    }

    ul {
      overflow: hidden;
      list-style: none;

      @include media-query(991px) {
        display: inline-block;
      }

      li {
        float: left;
      }

      > li + li {
        margin-left: 15px;
      }

      a {
        font-size: 14px;
        font-size: calc-rem-value(14);
        color: $text-color;
      }

      a:hover {
        color: $theme-primary-color;
      }
    }
  }
}

/*---------------------------------------------
	header style 3
----------------------------------------------*/
.header-style-3 {
  @extend .header-style-1;
  position: relative;

  @include widther(992px) {
    #navbar > ul > li a,
    .cart-search-contact .fi {
      color: $heading-color;
    }
  }

  @include media-query(991px) {
    .navigation {
      background: $white;
    }

    .cart-search-contact .fi {
      color: $heading-color;
    }
  }
}

.fixed-navbar {
  position: relative;
  z-index: 5555;
}

.fixed-navbar.active .site-header .navigation {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.fixed-navbar.active .header-style-1,
.fixed-navbar.active .header-style-2 {
  background: $white;

  .navigation {
    border: 0;
    background: $white;
  }
}

.fixed-navbar.active .header-style-1 {
  .navigation {
    background: rgba(255, 255, 255, 0.8);
  }
}

.fixed-navbar.active .header-style-3 {
  .navigation {
    background: $white;
  }
}
