/*--------------------------------------------------------------
	contact page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#5.1	contact-pg-section
--------------------------------------------------------------*/
.contact-pg-section {
  .submit-btn-wrapper {
    text-align: center;
  }

  form {
    overflow: hidden;
  }

  form .one-third-col {
    width: 33.33%;
    float: left;

    @include media-query(600px) {
      width: 100%;
      float: left;
    }
  }

  .form-field {
    margin-bottom: 30px;
  }

  .submit-area {
    text-align: center;
    margin-top: 40px;
  }

  .errorMessage {
    color: red;
    margin-top: 5px;
  }

  form .submit-btn-wrapper {
    padding-bottom: 0;
  }

  form input,
  form select,
  form textarea {
    background: #fdfdfd;
    font-size: 16px;
    font-size: calc-rem-value(16);
    height: 55px;
    padding: 6px 15px;
    border: 2px solid #f2f2f2;
    border-radius: 0;
    box-shadow: none;
    width: 100%;

    @include media-query(767px) {
      height: 45px;
    }

    &:focus {
      box-shadow: none;
      border-color: $theme-primary-color;
    }
  }

  form textarea {
    height: 200px;
    padding: 15px;
  }

  .contact-info {
    padding: 60px 35px;
    margin-top: 75px;
    box-shadow: 0px 9px 57px 0px rgba(0, 0, 0, 0.13);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @include media-query(991px) {
      padding: 60px 35px 40px;
      margin-top: 60px;
    }

    @include media-query(767px) {
      padding: 50px 25px 30px;
      margin-top: 50px;
    }

    > div {
      // width: 33.33%;
      //   width: 50%;
      float: left;
      position: relative;
      padding-left: 65px;

      @include media-query(991px) {
        width: 50%;
        padding-left: 55px;
        margin-bottom: 20px;
      }

      @include media-query(767px) {
        width: 100%;
        float: none;
      }
    }

    .icon {
      position: absolute;
      left: 0;
      top: 0;
    }

    .icon i {
      font-size: 50px;
      font-size: calc-rem-value(50);
      color: $theme-primary-color;

      @include media-query(991px) {
        font-size: 40px;
        font-size: calc-rem-value(40);
      }

      @include media-query(767px) {
        font-size: 35px;
        font-size: calc-rem-value(35);
      }
    }

    h5 {
      font-family: $base-font;
      font-size: 18px;
      font-size: calc-rem-value(18);
      margin: 0 0 0.05em;
    }
  }

  .contact-map {
    height: 450px;
    margin-top: 100px;

    @include media-query(991px) {
      margin-top: 80px;
    }

    @include media-query(767px) {
      height: 350px;
      margin-top: 70px;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
    }
  }
}
